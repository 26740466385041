<template>
  <div class="pledgelist-style">
    <div class="outbox">
      <el-card class="box-card">
        <van-tabs
          title-active-color="#323232"
          title-inactive-color="#909090"
          v-model="active"
          animated
          color="#323232"
          :line-width="80"
        >
          <van-tab :title="$t('text.t212')">
            <div class="outb2">
              <div class="box2 between-center">
                <div class="box2-item">{{$t('text.t213')}}</div>
                <div class="box2-item">{{$t('text.t127')}}</div>
                <div class="box2-item">{{$t('text.t118')}}</div>
              </div>
              <div>
                <van-list
                  v-model="loading"
                  :finished="finished"
                  :finished-text="$t('text.t39')"
                  :offset="10"
                  @load="getlist"
                >
                  <div
                    class="box3 between-center"
                    v-for="item in list" :key="item.id"
                  >
                    <div class="box3-item center-center">
                      <div>True NFT</div>
                      <img
                        src="@/assets/img/img41.png"
                        alt=""
                        class="box3-item-i1"
                        @click="changeShow(item.tokenid)"
                      />
                    </div>
                    <div class="box3-item">{{item.number}}</div>
                    <div class="box3-item">{{$parseTime(item.createtime,"{y}/{m}/{d}")}}</div>
                  </div>
                </van-list>
              </div>
            </div>
          </van-tab>
          <van-tab :title="$t('text.t214')">
            <div class="outb2">
              <el-card class="outb2-card">
                <div class="outb2-card-b1 between-center">
                  <div>{{$t('text.t215')}}</div>
                  <div>{{info.totalHashrate}} PE</div>
                </div>
                <div class="outb2-card-b2 between-center">
                  <div>{{$t('text.t216')}}</div>
                  <div>{{info.myHashrate}} PE</div>
                </div>
                <div class="outb2-card-b2 between-center">
                  <div>{{$t('text.t236')}}</div>
                  <div>{{info.DirectpushNum}}</div>
                </div>
                <div class="outb2-card-b2 between-center">
                  <div>{{$t('text.t217')}}</div>
                  <div>{{info.Directpush}} PE</div>
                </div>
                <div class="outb2-card-b2 between-center">
                  <div>{{$t('text.t237')}}</div>
                  <div>{{info.InterpositionNum}}</div>
                </div>
                <div class="outb2-card-b2 between-center">
                  <div>{{$t('text.t218')}}</div>
                  <div>{{info.Interposition}} PE</div>
                </div>
                <div class="outb2-card-b2 between-center">
                  <div>{{$t('text.t238')}}</div>
                  <div>{{info.nodeDirectpushNum}}</div>
                </div>
                <div class="outb2-card-b2 between-center">
                  <div>{{$t('text.t219')}}</div>
                  <div>{{info.nodeDirectpush}} PE</div>
                </div>
                <div class="outb2-card-b2 between-center">
                  <div>{{$t('text.t251')}}</div>
                  <div>{{info.nodepush}} PE</div>
                </div>
                <template v-if="info.level==9">
                  <div class="outb2-card-b2 between-center">
                    <div>{{$t('text.t252')}}</div>
                    <div>{{info.cultivation}} PE</div>
                  </div>
                  <div class="outb2-card-b2 between-center">
                    <div>{{$t('text.t253')}}</div>
                    <div>{{info.push}} PE</div>
                  </div>
                </template>
                <!-- <div class="outb2-card-b2 between-center" v-if="(info.info !== null)">
                  <div>{{$t('text.t336')}}</div>
                  <div>{{info.info?.type==0?'+':info.info?.type==1?'-':''}}{{info.info?.flag}}%</div>
                </div> -->
              </el-card>
            </div>
          </van-tab>
        </van-tabs>
      </el-card>
    </div>
    <van-popup round v-model="show">
      <div class="ppbox">
        <div class="ppbox-t1">{{$t('text.t221')}}</div>
        <div class="ppbox-b1 between-center">
          <div class="ppbox-b1-item" v-for="(t, i) in idlist" :key="i">
            True NFT#{{t}}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      active: 0,
      show: false,
      info:{},
      idlist:[],

      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
    };
  },
  computed: {},
  created(){
    this.getoverview()
  },
  mounted() {},
  methods: {
    changeShow(tokenid){
      console.log(tokenid);
      this.idlist = tokenid.toString().split(',')
      this.show = true
    },
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        type: 0,
      };
      this.$http.get("/redeem/pledgeRedeemLog", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
    getoverview(){
      this.$http.get('/index/overview').then(res=>{
        this.info = res.data
      })
    }
  },
};
</script>

<style lang="less">
.pledgelist-style {
  .outbox {
    padding: 20px 0;
    .box-card {
      width: 350px;
      border-radius: 20px;
      margin: auto;
      min-height: 550px;
      .box1 {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 25px;
        text-align: center;
      }
      .outb2 {
        padding-top: 20px;
        .outb2-card {
          width: 294px;
          border-radius: 10px;
          margin: 0 auto 25px;
          box-sizing: border-box;
          font-weight: bold;
          color: #323232;
          .outb2-card-b1 {
            margin-bottom: 20px;
            font-size: 15px;
          }
          .outb2-card-b2 {
            font-size: 11px;
            margin-bottom: 15px;
            div:first-of-type{
              max-width: 70%;
            }
          }
          .outb2-card-b2:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .box2 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        margin-bottom: 5px;
        .box2-item {
          width: 33.3%;
        }
      }
      .box3 {
        font-size: 9px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        padding: 16px 0;
        border-bottom: 1px solid #e1e1e1;
        .box3-item {
          width: 33.3%;
          .box3-item-i1 {
            width: 8px;
            height: 8px;
            margin-left: 4px;
          }
        }
      }
    }
  }
  .ppbox {
    width: 218px;
    padding: 20px 0 4px;
    .ppbox-t1 {
      font-size: 10px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 22px;
      text-align: center;
    }
    .ppbox-b1 {
      font-size: 9px;
      font-weight: 500;
      color: #323232;
      text-align: center;
      flex-wrap: wrap;
      .ppbox-b1-item {
        width: 50%;
        margin-bottom: 20px;
      }
    }
  }
}
</style>